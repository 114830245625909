.button-add {
    margin-top: 3px;
    padding: 3px;
    background-color: #0052cc;
    height: 30px;
    width: 30px;
    color: #fff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-bottom: 0;
}

.button-add:hover {
    background-color: #0052ccc0;
}

.button-remove {
    margin-top: 3px;
    padding: 3px;
    background-color: #E7B43F;
    height: 30px;
    width: 30px;
    color: #fff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-bottom: 0;
}

.button-remove:hover {
    background-color: #e7b53fbd;
}

.button-hover {
    transition: 0.5s;
    cursor: pointer;
}

.button-hover:hover {
    transform: scale(0.9, 0.9);
}

.button-back-100 {
    padding: 8px 24px;
    width: 100%;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #aeaeae;
    font-weight: 600;
    color: #fff;
}

.button-back-100:hover {
    background-color: #aeaeae;
    color: #fff;
}

.button-close-100 {
    padding: 8px 24px;
    border-radius: 5px;
    width: 100%;
    border: none;
    outline: none;
    border: 2px solid var(--cor-cinza-a);
    background-color: #ececec00;
    font-weight: 600;
    color: var(--cor-cinza-a);
}

.button-close-100:hover {
    background-color: #f2f2f2c2;
}

.button-close {
    padding: 8px 24px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #ececec;
    font-weight: 600;
    color: #707070;
}

.button-close:hover {
    background-color: #f2f2f2c2;
}

.button-save-0 {
    padding: 2px 15px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #2c55a0;
    color: #fff;
    font-weight: 600;
    transition: background-color 0.5s ease, color 0.5s ease;
}

a.button-save-0 {
    padding-block: 10px;
    display: inline-block;
}

.button-save-0:hover {
    background-color: #2c55a0b2;
    color: #fff;
    transition: background-color 0.5s ease, color 0.5s ease;
}


.button-save {
    padding: 8px 15px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: var(--cor-primary);
    color: #fff;
    font-weight: 600;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.button-save:hover {
    background-color: #2c55a0b2;
    color: #fff;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.button-save-100 {
    width: 100%;
    padding: 10px 24px;
    border-radius: 5px;
    border: none;
    outline: none;
    margin: 0;
    background-color: var(--cor-primary);
    color: #fff;
    font-weight: 600;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.button-save-100:hover {
    background-color: #2c55a0b2;
    color: #fff;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.button-save-small {
    width: 100%;
    padding: 5px 24px;
    border-radius: 5px;
    border: none;
    outline: none;
    margin: 0;
    background-color: var(--cor-azul-a);
    color: #fff;
    font-weight: 600;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.button-save-small:hover {
    background-color: #2a75ffa1;
    color: #fff;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.button-verde-100 {
    width: 100%;
    padding: 8px 24px;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: #2eb85c;
    color: #fff;
    font-weight: 600;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.button-verde-100:hover {
    background-color: #2eb85ca5;
    color: #fff;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.button-danger {
    width: 100%;
    padding: 8px 24px;
    border-radius: 15px;
    border: none;
    outline: none;
    background-color: var(--cor-vermelho);
    color: #fff;
    font-weight: 600;
}

.button-danger:hover {
    background-color: #e55353;
}

.button-blue-secondary {
    width: 100%;
    padding: 8px 24px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #0052cc;
    color: #fff;
}

.button-blue-secondary:hover {
    background-color: #0052ccb2;
}

.button-red {
    background-color: #e65f5f;
    padding: 6px;
    width: 100%;
    color: #fff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-right: 5px;
}


.button-yellow {
    background-color: #f7ac00;
    padding: 6px;
    width: 100%;
    color: #fff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-right: 5px;
}

.button-save-header {
    padding: 8px 15px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #2c55a0;
    color: #fff;
    font-weight: 600;
    transition: background-color 0.5s ease, color 0.5s ease;
}

@media (max-width: 500px) {

    .button-save-header {
        width: 285px;
    }

}

@media (max-width: 420px) {
    .button-save-header {
        width: 298px;
    }
}

@media (max-width: 360px) {
    .button-save-header {
        width: 225px;
    }
}

.button-cabecalho {
    padding: 8px 15px;
    border-radius: 5px;
    height: 40px;
    border: none;
    outline: none;
    background-color: #2c55a0;
    color: #fff;
    font-weight: 600;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.button-cabecalho:hover {
    background-color: #2c55a0b2;
    color: #fff;
    transition: background-color 0.5s ease, color 0.5s ease;
}

@media (max-width: 600px) {
    .button-cabecalho {
        width: 100%;
    }

}

.button-blue {
    padding: 4px;
    max-width: 70px;
    background-color: #0052cc;
    color: #fff;
    font-size: 12px;
    border-radius: 5px;
    border: none;
}

.button-table-green {
    background-color: #16c969;
    color: #fff;
    border-radius: 5px;
    padding: 0;
    border: none;
    cursor: pointer;
}

.button-align {
    grid-area: button-align;
    width: 100%;
}

.button-align.button-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-blue p,
.button-blue pre {
    color: #2c55a0;
    font-size: 14px;
}

button.button-blue {
    margin-bottom: 10px;
    text-align: center;
    border-radius: 10px;
    font-size: 14px;
    padding-right: 10px;
    padding-left: 10px;
}