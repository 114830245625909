.title-tab-primary {
  color: var(--cor-primary);
  font-size: 15px;
  border-bottom: 3px solid var(--cor-primary);
  padding: 10px 20px 10px;
  margin: 0px 5px;
  display: inline-block;
  background-color: white;
}

.title-tab-primary-disabled {
  color: #acacac;
  background-color: #fff;
  border-bottom: 3px solid #fff;
  padding: 10px;
}

.title-tab {
  color: gray;
  font-size: 15px;
  position: relative;
  top: 0px;
  display: inline-block;
  background-color: white;
  transition: 0.5s;
  margin: 5px 5px;
}

.title-tab label {
  cursor: pointer;
  margin: 0px 10px;
}

.title-tab>input:checked+label {
  color: var(--cor-azul-a);
  font-size: 15px;
  border-bottom: 3px solid var(--cor-azul-a);
  /* top: 6px; */
  padding: 10px 20px 10px;
  margin: -6px 5px;
  display: inline-block;
  background-color: white;
}

.title-tab input[type='radio'] {
  background-color: #fff;
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.line-tab {
  border-top: 1px solid var(--cor-cinza-c);
}

.view-tab-list {
  margin-bottom: 10px;
}

.view-tab-list .tab-component>input+label {
  margin: 0;
  border-radius: 5px;
  padding: 10px;
  background-color: var(--cor-cinza-c);
  color: var(--cor-cinza-b);
}

.view-tab-list .tab-component>input:checked+label {
  margin: 0;
  border-radius: 5px;
  padding: 10px;
  background-color: var(--cor-azul-a);
  color: #fff;
  border: 0;
}

.view-tab-list .tab-component>input:checked+label::after {
  content: '';
  color: red;
  border-bottom: 3px solid var(--cor-azul-a);
  position: absolute;
  top: 130%;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .title-tab {
    font-size: 13px;
    padding: 10px 5px 0px;
    border: none;
  }

  .title-tab:hover {
    color: #2c55a0;
    border: none;
    position: relative;
    top: 0px;
    left: 0;
    border-bottom: none;
    background-color: rgba(255, 255, 255, 0);
  }

  .title-tab>input:checked+label {
    border: none;
    padding: 0px 0px 0px;
    top: 0px;
  }

  .line-tab {
    border-top: none;
  }
}

@media only screen and (max-width: 800px) {
  .title-tab {
    font-size: 13px;
    padding: 10px 5px 0px;
    border: none;
  }

  .title-tab:hover {
    padding: 10px 5px 0px;
  }

  .title-tab>input:checked+label {
    border: none;
    padding: 0px 0px 0px;
    top: 0px;
  }
}