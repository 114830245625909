/*------------------------- RESPONSIVIDADE ------------------------- */
@media (max-width: 1052px) {

  .d-flex.docs-highlight {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .divCabecalho {
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
  }


  .dropdown-cadastrar {
    color: #9e9e9e;
    font-size: 16px;
    border-radius: 10px;
    margin-bottom: 10px;
    text-align: center;
    padding-top: 0px;
    background-color: #f2f2f2;
    padding-bottom: 7%;
    height: 50px;
  }

  @media only screen and (max-width: 600px) {
    .dropdown-cadastrar {
      width: 100%;
    }

    .dropdown-menu.show {
      transform: translate3d(0px, -100.2px, 0px);
    }
  }


  #title-page {
    width: 100%;
  }
}

/* CORREÇÃO DA SIDEBAR DESAPARECER QUANDO ESTÁ EM 768PX */
@media (min-width: 768px) {
  html:not([dir='rtl']) .sidebar.hide:not(.sidebar-end) {
    margin-left: calc(0 * var(--cui-sidebar-width));
  }
}


/* COR DE FUNDO DO BACKGROUND */
.bg-light {
  --cui-bg-opacity: 0;
  background-color: rgba(var(--cui-light-rgb), var(--cui-bg-opacity)) !important;
}

/* ADICIONA MARGEM E SOMBRA */

.mb-6 {
  width: 300px;
  height: 300px;
}

.button-align {
  grid-area: button-align;
  width: 100%;
}

.button-align.button-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification_wrap .dropdown .notify_item .notify_info .notify_time {
  color: #acacac;
  font-size: 13px;
}


.button-blue-dropdown {
  margin-bottom: 10px;
  text-align: center;
  border-radius: 10px;
  font-size: 14px;
  padding-right: 10px;
  padding-left: 10px;
  color: #2c55a0;
}



.mb-6 {
  width: 300px;
  height: 300px;
}

.button-align {
  grid-area: button-align;
  width: 100%;
}

.button-align.button-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.div-tab {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 7px 20px #00000029;
  width: 100%;
}

.cabecalho-tab {
  padding: 15px;
  border-bottom: 1px solid var(--cor-cinza-a);
}

.titulo-tab {
  font-size: 18px;
  color: var(--cor-azul-a);
  font-weight: bold;
}

.conteudo-tab {
  padding: 15px;
}

.button-edit-green-small {
  padding: 6px;
  width: 100%;
  background-color: #16c969;
  color: #fff;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

/* ESTILIZAÇÃO MODAL DE CADASTRO DE DIRETRIZES */
.titulo-modal-cadastrar {
  color: #2c55a0;
  margin-top: 30px;
  padding-bottom: 10px;
  font-size: 25px;
}

.content-modal-cadastro {
  width: 100%;
  height: 60vh;
  margin: auto;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px 40px 20px;
}

@media screen and (max-width: 668px) {
  .content-modal-cadastro {
    width: 100%;
    height: 50vh;
    margin: auto;
    justify-content: space-around;
    align-items: center;
    padding: 10px 20px 40px 20px;
  }
}

.titulo-modal-cadastrar {
  font-size: 30px;
  font-weight: 700;
  color: var(--cor-primary);
}

textarea#mensagem {
  font-size: 15px;
  color: var(--cor-cinza-b);
  width: 100%;
  height: 80px;
  border-radius: 15px;
  border: solid 1px #c6c6c6;
  outline: none;
  padding: 10px 20px 0 20px;
  margin-top: 10px;
}

.button-salvar-diretrizes {
  /* padding: 10px 25px 10px 25px;
     */
  border: none;
  border-radius: 15px;
  background-color: var(--cor-cinza-c);
  cursor: pointer;
  width: 100%;
  color: #2c55a0;
  font-size: 14px;
}

.text-button-salvar-diretrizes {
  color: #2c55a0;
  font-size: 14px;
}

.cont-modal {
  flex: 1 1 300px;
  padding-left: 10px;
  padding-right: 10px;
}


.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--cui-modal-header-padding);
  border-bottom: none;
}

.titulo-modal-cadastrar-diretrizes {
  color: #2c55a0;
  font-size: 32px;
  padding-bottom: 20px;
}

/* ESTILIZAÇÃO MODAL DE EDIÇÃO DE DIRETRIZES */
.titulo-modal-editar-diretrizes {
  color: #2c55a0;
  font-size: 32px;
  font-weight: 600;
  padding-bottom: 5px;
}

/* REMOVE A SETINHA DO DROPDOWN */
.dropdown-toggle::after {
  display: none;
}

.dropdown-cadastrar-title {
  transition: 0.5s;
  color: #2c55a0;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  padding-bottom: -5px;
  background-color: #f2f2f2;
  border-radius: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 800px) {
  .dropdown-cadastrar-title {

    width: 100%;
  }
}

.dropdown-cadastrar-title:hover {
  transform: scale(0.9, 0.9);
}

.dropdown-menu {
  border: none;
  box-shadow: 0px 7px 20px #00000029;
}


@media only screen and (max-width: 800px) {
  #title-page {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.title-diretrizes {
  color: #9e9e9e;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  padding-top: 5px;
  padding-bottom: -5px;
}

.texto {
  color: #3e3c3c;
  font-weight: bold;
  font-size: 16px;
}

#title-page-mobile-diretrizes {
  color: var(--cor-cinza-b);
  font-size: 16px;
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
}