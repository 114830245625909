/*Big-x*/
.spinner-overlay-big-x {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

/*Big*/
.spinner-overlay-big {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

/*Small-x*/

.spinner-overlay-small-x {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
}

/*Small*/

.spinner-overlay-small {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15vh;
}

.spinner-overlay-small .line {
    width: 40px;
    height: 5px;
    border-radius: 5px;
    margin: 3px 0;
    position: relative;
}

.spinner {
    width: 135px;
}

.line {
    width: 60px;
    height: 6px;
    border-radius: 5px;
    margin: 5px 0;
    position: relative;
}

.line:nth-child(1) {
    animation: move1 1s infinite alternate;
    background-color: #2c55a092;
}

.line:nth-child(2) {
    animation: move2 1s infinite alternate;
    background-color: #2c55a0ca;
}

.line:nth-child(3) {
    animation: move3 1s infinite alternate;
    background-color: #2c55a0;
}

.line:nth-child(4) {
    animation: move4 1s infinite alternate;
    background-color: #2c55a0ca;
}

.line:nth-child(5) {
    animation: move5 1s infinite alternate;
    background-color: #2c55a092;
}

@keyframes move1 {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(30px);
    }
}

@keyframes move2 {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(50px);
    }
}

@keyframes move3 {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(70px);
    }
}

@keyframes move4 {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(50px);
    }
}

@keyframes move5 {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(30px);
    }
}

.spinner-global {
    width: 150px;
    height: 150px;
    transition: 0.5s;
}

.spinner-border {
    border: 7px solid currentcolor;
    border-right-color: transparent;
    background-color: transparent;
    box-shadow: 0px 7px 20px #2c55a023;
}

.spinner-border-login {
    border: 2px solid currentcolor;
    border-right-color: transparent;
    background-color: transparent;
}

/*********************************/

.spinner-dashboard {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
}

.spinner-dashboard-height-260 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 260px;
    width: 100%;
}