html:not([dir=rtl]) .form-select-lg {
    padding-left: 0.65rem;
    padding-top: 19px;
}

.form-select {
    border-radius: 10px;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
    height: calc(2.5rem + 2px);
    min-height: calc(3rem + 2px);
    line-height: 1.25;
}

.form-select {
    border: 1px solid var(--cor-secondary);
}

.form-floating>label {
    position: absolute;
    top: 0;
    width: 100%;
    font-size: 13px;
    padding: 0.85rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-control-plaintext~label,
.form-floating>.form-select~label {
    opacity: 3;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    font-size: 15px;
    color: var(--cor-secondary);
}

.form-control {
    border-color: var(--cor-secondary);
    color: var(--cor-secondary);
    border-radius: 10px;
    font-size: 14px;
}

.form-check-input:checked {
    background-color: var(--cor-primary);
    border-color: var(--cui-form-check-input-checked-border-color, #2c55a0);
}

.form-control {
    color: var(--cor-secondary);
    font-weight: 600;
}

.form-label {
    color: var(--cor-secondary);
    background-color: none;
}

.col-form-label {
    color: var(--cor-azul-a);
}

.form-floating> :disabled~label,
.form-floating>.form-control:disabled~label {
    color: var(--cor-secondary);
}

.form-floating> :disabled~label::after,
.form-floating>.form-control:disabled~label::after {
    background: transparent;
}

.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-select~label::after {
    background-color: #ffffff00;
}

.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-select~label::after {
    height: 0.5em;
}

.form-check-input {
    width: 10px;
    height: 10px;
    flex: 0 1 10px;
}

.form-floating>.form-select {
    padding-top: 20px;
    padding-bottom: 0.625rem;
    font-weight: 600;
}

.form-multi-select-optgroup-label {
    padding: 0.75rem 0.625rem 0.5rem 0.625rem;
    font-size: 80%;
    font-weight: 700;
    color: var(--cor-primary);
    text-transform: uppercase;
}