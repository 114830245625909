@media (max-width: 1052px) {

  .d-flex.docs-highlight {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .divCabecalho {
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  html:not([dir='rtl']) .sidebar.hide:not(.sidebar-end) {
    margin-left: calc(0 * var(--cui-sidebar-width));
  }
}

p,
pre {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1rem;
}


pre {
  font-family: 'Nunito', sans-serif;
  white-space: pre-wrap;
  word-wrap: break-word;
  width: 100%;
}

div.name {
  color: #2c55a0;
}

.mb-6 {
  width: 300px;
  height: 300px;
}

.textareaContainer {
  display: flex;
  width: 100%;
  align-items: center;
}

.textareaContainer div {
  flex-grow: 1;
}

.textButton {
  background-color: #588CED;
  border-radius: 5px;
  padding: 5px 15px;
  color: #fff;
  font-size: .85rem;
  margin: 0;
}

.remove {
  background-color: #F7AC00;
}

.notification_wrap .dropdown .notify_item .notify_info .notify_time {
  color: #acacac;
  font-size: 13px;
}

.text-medium-emphasis {
  font-size: 15px;
  white-space: nowrap;
  --cui-text-opacity: 1;
}

.maginInitial {
  margin-bottom: 6px;
  margin-top: 6px;
}

.text-blue {
  --cui-text-opacity: 1;
  color: rgba(var(--cui-dark-rgb), var(--cui-text-opacity)) !important;
}

.d-none {
  display: flex;
}

.bg-light {
  --cui-bg-opacity: 0;
  background-color: rgba(var(--cui-light-rgb), var(--cui-bg-opacity)) !important;
}

.gray-bg {
  background-color: #e4e4e4;
  color: #000;
}


.p-2 {
  padding: none;
}

html:not([dir='rtl']) .header-brand {
  margin-right: 0px;
}

.input-arquivo {
  display: none;
}

.label-arquivo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
  background-color: #588CED;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  min-width: fit-content;
}

.label-arquivo span {
  font-size: 14px;
  color: #fff;
}

.label-arquivo span:nth-of-type(2) {
  margin-left: .3rem;
}

.label-arquivo:hover {
  background-color: #588cedd2;
}

.input-arquivo:focus+.label-arquivo {
  outline: 1px dotted #999;
  outline: -webkit-focus-ring-color auto 5px;
}

.titulo-filtro {
  padding: 15px;
  border-bottom: 1px solid var(--cor-cinza-a);
}

form#filtro {
  margin-bottom: 15px;
}

select#filtro {
  border: solid 1px var(--cor-secondary);
  width: 100%;
  height: 38px;
  padding: 8px;
  border-radius: 15px;
  font-size: 14px;
  color: var(--cor-primary);
  outline: none;
}

select#filtro:hover {
  border: solid 1px var(--cor-secondary);
  box-shadow: none;
}

select#conformidade {
  border: solid 1px var(--cor-secondary);
  width: 100%;
  height: 38px;
  padding: 8px;
  border-radius: 15px;
  font-size: 14px;
  color: var(--cor-secondary);
  outline: none;
}

.cont-select {
  margin-top: 5px;
}

select.ls-select {
  flex: 1 1 200px;
  border: solid 1px var(--cor-secondary);
  width: 100%;
  height: 38px;
  padding: 8px;
  border-radius: 15px;
  color: var(--cor-secondary);
  outline: none;
}

#filtro-d {
  flex: 1 1 200px;
  border: solid 1px var(--cor-secondary);
  width: 100%;
  height: 38px;
  padding: 8px;
  border-radius: 15px;
  color: var(--cor-secondary);
  outline: none;
  font-size: 13px;
}

.drop-icon {
  position: relative;
  top: 13px;
  left: -35px;
}

input#filtro {
  flex: 1 1 40px;
  border: solid 1px var(--cor-secondary);
  width: 100%;
  height: 38px;
  padding: 8px;
  border-radius: 10px;
  color: var(--cor-secondary);
  outline: none;
  font-size: 14px;
  margin-right: 17px;
}

#filtro::placeholder {
  color: var(--cor-secondary);
}

input#conformidade {
  flex: 1 1 100px;
  border: solid 1px var(--cor-secondary);
  width: 100%;
  height: 38px;
  padding: 8px;
  border-radius: 10px;
  color: var(--cor-secondary);
  outline: none;
}

@media only screen and (max-width: 600px) {
  input#conformidade {
    margin-bottom: 10px;
  }
}

#conformidade::placeholder {
  color: var(--cor-secondary);
}

input#buscar {
  flex: 1 1 100px;
  margin-bottom: 50px;
  border: none;
  width: 100%;
  align-items: center;
  height: 38px;
  padding: 8px;
  border-radius: 15px;
  color: var(--cor-primary);
  cursor: pointer;
  outline: none;
  background-color: var(--cor-cinza-c);
}

input#filtro:not(:placeholder-shown) {
  font-size: 13px;
  top: 0;
  color: var(--cor-secondary);
}

input#filtro[type='button'] {
  margin: 0px;
  padding: 10px 25px 10px 25px;
  width: 100%;
  border: none;
  border-radius: 30px;
  background-color: var(--cor-cinza-c);
  cursor: pointer;
  font-size: 14px;
}

select {
  word-wrap: normal;
  flex: 1 1 100px;
  border: solid 1px var(--cor-cinza-b);
  width: 100%;
  border-radius: 10px;
  padding: 12px;
  color: var(--cor-primary);
  outline: none;
  font-size: 14px;
  margin-right: 20px;
  padding: 14px;
}

@media (max-width: 600px) {
  select {
    margin-right: 0px;
  }

  .textareaContainer {
    flex-direction: column;
    align-items: initial;
    margin-bottom: 2.2rem;
  }

  .textareaContainer div {
    margin-bottom: .4rem;
  }

  .textareaContainer button {
    align-self: flex-end;
  }
}

@media (max-width: 800px) {

  .col-form-label {
    margin-right: 10px;
  }
}

.select-item-margin {
  flex: 1 1 100px;
  margin-right: 10px;
  margin-top: 15px;
}

.select-item-margin p,
.select-item-margin pre {
  margin-top: 10px;
}

.select-item-2 {
  flex: 1 1 250px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.select-item-2 p,
.select-item-2 pre {
  margin-top: 5px;
}

h3 {
  font-size: 18px;
  color: var(--cor-azul-a);
  font-weight: bold;
}


.padding-30 {
  padding: 30px 15px;
}

.margin-right-10 {
  margin-right: 10px;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.cont-select {
  margin-top: 5px;
}

.select-item-margin {
  flex: 1 1 100px;
  margin-right: 10px;
  margin-top: 15px;
}

.select-item-margin p,
.select-item-margin p {
  margin-top: 10px;
}

.select-item {
  flex: 1 1 100px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .select-item {
    flex: 1 1 20px;
    margin: 5px 0px;
  }
}

.select-item p,
.select-item pre {
  margin-top: 5px;
  overflow-wrap: break-word;
  max-width: 600px;
}

@media only screen and (max-width: 600px) {

  .select-item p,
  .select-item pre {
    margin-top: 5px;
    overflow-wrap: break-word;
    max-width: 250px;
  }
}


.select-item a {
  overflow-wrap: break-word;
  max-width: 300px;
}

.select-item-2 {
  flex: 1 1 250px;
  margin-bottom: 10px;
}


.border {
  border: none;
}

.titulo-modal-nao-conformiidade {
  color: var(--cor-azul-a);
  padding-bottom: 10px;
  font-size: 25px;
}

html:not([dir=rtl]) .modal-header .btn-close {
  margin: 0;
}


.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  border-bottom: none;
}


textarea::placeholder {
  color: var(--cor-secondary);
}

.form-descricao {
  margin-top: 20px;
}

textarea#form {
  font-size: 15px;
  color: var(--cor-secondary);
  width: 100%;
  height: 80px;
  border-radius: 15px;
  border: solid 1px var(--cor-secondary);
  outline: none;
  padding: 10px 20px 0 20px;
  margin-top: 10px;
}

.button-blue-proximo p,
.button-blue-proximo pre {
  color: #2c55a0;
  font-size: 14px;
}


cabecalho-modal {
  font-size: 15px;
  color: #acacac;
  margin-bottom: 1.5rem;
}

textarea#form-p {
  font-size: 15px;
  color: var(--cor-secondary);
  width: 100%;
  height: 50px;
  border-radius: 15px;
  border: solid 1px var(--cor-secondary);
  outline: none;
  padding: 10px 20px 0 20px;
  margin-top: 10px;
}

.plano-acao {
  justify-content: flex-start;
  margin-bottom: 20px;
}


.justify-space-between {
  justify-content: space-between;
}

textarea#conformidade {
  border: solid 1px var(--cor-input);
  color: var(--cor-input);
  font-size: 15px;
  width: 100%;
  border-radius: 10px;
  height: 38px;
  outline: none;
  padding: 6px 20px 0 20px;
}

.cabecalho-acao {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-arquivo {
  margin-right: 10px;
  margin-left: 10px;
}

.font-18 {
  font-size: 18px;
}

.cont-line-a {
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #e9e9e9;
}

.cont-line-b {
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: #cccccc;
}


.text-button-plano-acao {
  color: white;
  font-weight: bold;
  font-size: larger;
  margin-bottom: 0;
}

.selectDocumentList {
  list-style: none;
}

.selectDocumentList li {
  display: block;
  word-break: break-all;
}

.selectDocumentList li:not(.selectDocumentList li:last-child) {
  margin-bottom: 1rem;
}

.modal {
  --cui-modal-header-border-color: none;
}

.content-nonconformity {
  border-radius: 10px;
  padding: 20px 30px;
  width: 100%;
  box-shadow: 0px 7px 20px #00000029;
}

.content-newconformity {
  border-radius: 10px;
  padding: 20px 30px;
  width: 100%;
  box-shadow: 0px 7px 20px #00000029;
}