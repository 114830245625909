.backgound {
    background: linear-gradient(180deg, #3968be15, #eeeeee, #3968be2e);
}

.logo {
    margin: 0 auto;
    animation: slideUp 0.8s ease-in-out forwards 0.1s;
    text-align: center;
}

.align-welcome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 850px;
    border-radius: 20px 70px 20px 70px;
    margin: 40px;
    background-color: #ffffff;
    position: relative;
    right: 20px;
}

.text-welcome {
    padding: 3rem 3rem 1rem;
    flex-direction: column;
}


.text-welcome h2 {
    padding-bottom: 1%;
    color: var(--cor-azul-a);
    font-weight: 600;
}

.text-welcome p {
    color: var(--cor-cinza-b);
    font-size: 16px;
}

.text-welcome h6 {
    color: #2c55a0;
    font-size: 15px;
    font-weight: 600;
}

.cont-login {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.cont-login span {
    color: var(--cor-azul-a);
    font-size: 32px;
}

.cont-login p {
    margin-top: -10px;
    color: var(--cor-primary);
    font-size: 26px;
    text-align: center;
    position: relative;
    top: 50px;
}

.content-login {
    padding: 1rem;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

img.desenho {
    margin: 0 auto;
    position: relative;
    top: 40px;
    left: -50px;
    width: 780px;
}

.footer-login {
    text-align: center;
    position: relative;
    top: -20px;
}

.footer-login h6 {
    font-size: 12px;
    font-weight: bold;
    color: #515151;
}


.navigation {
    margin: 20px;
    position: fixed;
    z-index: 5;
}


.login-color {
    background-color: #2c55a0;
    transition: 3s;
}

/***************/
.container-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
        'nav nav nav nav nav nav nav nav nav nav nav nav'
        'container container container container container container container container container container container container';
}

nav {
    grid-area: nav;
}

nav a {
    padding: 5px;
    display: flex;
    align-items: center;
    text-decoration: none;
}

nav p {
    color: #fff;
    padding: 10px;
}

/*******************/
.container {
    grid-area: container;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*******************/

@keyframes slideUp {
    0% {
        transform: translateY(300px);
    }

    100% {
        transform: translateY(0px);
    }
}


/*******************/
form {
    width: 100%;
}

.text-login {
    text-align: center;
    padding: 50px 20px 20px 10px;
    flex-direction: column;
    align-items: center;
}

.text-login h2 {
    padding-bottom: 1%;
    color: var(--cor-azul-a);
    font-weight: 600;
}

.text-login p {
    color: var(--cor-primary);
    font-size: 18px;
    font-weight: 600;
}

.redefini-forgot {
    margin: 10px 5px;
    font-size: 15px;
    color: var(--cor-azul-a);
}


/*********enviar**********/
.enviar {
    padding: 3% 1% 3% 1%;
    text-align: center;
}

/*******************/
.cont-divider {
    display: block;
    height: 2px;
    width: 80%;
    background-color: #c6c6c6;
    margin-top: 30px;
    margin-bottom: 20px;
    margin: 0 auto;
}

.cont-1 {
    display: flex;
    align-items: center;
    padding: 20px;
}

.cont-1 img {
    width: 30px;
    height: 30px;
    padding: 5px;
}

.cont-1 h3 {
    color: var(--cor-azul-principal);
    font-weight: 400;
}

/*******************************************/


.cont-redes {
    text-align: center;
    margin: 0 auto;
}

.wrapper {
    display: flex;
    justify-content: center;
}

.wrapper .icon-login {
    position: relative;
    background: #c6c6c6;
    border-radius: 50%;
    padding: 30px;
    margin: 10px;
    width: 60px;
    height: 60px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
    position: absolute;
    content: '';
    height: 8px;
    width: 8px;
    background: #ffffff;
    bottom: -4px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
    top: -55px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .linkedin:hover,
.wrapper .linkedin:hover .tooltip,
.wrapper .linkedin:hover .tooltip::before {
    background: #2c55a0;
    color: #ffffff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
    background: #2c55a0;
    color: #ffffff;
}

.wrapper .whats:hover,
.wrapper .whats:hover .tooltip,
.wrapper .whats:hover .tooltip::before {
    background: #2c55a0;
    color: #fff;
}

/********media*********/

@media only screen and (max-width: 1300px) {
    .align-welcome {
        height: 100%;
        margin: 30px;
    }

    img.desenho {
        width: 500px;
    }
}

@media only screen and (max-width: 1000px) {

    .content-login {
        display: none;
    }

    .visible {
        display: contents;
    }

    .backgound {
        background: none;
    }

}

@media only screen and (max-width: 800px) {

    .content-login {
        display: none;
    }

    .visible {
        display: contents;
    }

    .backgound {
        background: none;
    }

    .container {
        flex-direction: column;
        align-items: baseline;
    }

    .text-login {
        text-align: center;
        padding: 10px 30px 10px 30px;
        flex-direction: column;
        align-items: center;
    }

    #email {
        width: 100%;
    }

    #senha {
        width: 100%;
    }

    #enviar {
        width: 100%;
    }

    #name {
        width: 100%;
    }

    #phone {
        width: 100%;
    }

    #empresa {
        width: 100%;
    }

    #setor {
        width: 100%;
    }

    .text-login h1 {
        font-size: 25px;
    }

    .text-login p {
        font-size: 15px;
    }

    .cont-divider {
        width: 100%;
        text-align: center;
    }

    .wrapper .icon {
        width: 30px;
        height: 30px;
        font-size: 15px;
    }

    .label-form label {
        left: 20px;
    }
}


html:not([dir='rtl']) .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
html:not([dir='rtl']) .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
html:not([dir='rtl']) .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
html:not([dir='rtl']) .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select {
    border-bottom-right-radius: 15px;
}

html:not([dir='rtl']) .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
html:not([dir='rtl']) .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
html:not([dir='rtl']) .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
html:not([dir='rtl']) .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select {
    border-top-right-radius: 15px;
}


.button-save-100 {
    width: 100%;
    border-radius: 5px;
    background-color: #2c55a0;
    color: #fff;
    font-weight: 600;
}

.center-div {
    flex: 1;
    width: 100%;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    align-items: center;
}